import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=505289f4&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=505289f4&prod&lang=less&scoped=true&"
import style1 from "./detail.vue?vue&type=style&index=1&id=505289f4&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505289f4",
  null
  
)

export default component.exports